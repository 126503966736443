<template>
  <div class="name-card">
    <v-card :color="color || colors[Math.floor(Math.random() * this.colors.length)]" ref="card" :dark="dark" :img="cardBgImage">
      <v-responsive v-if="showTopNav">
        <v-layout row class="mx-2 my-3">
          <v-spacer></v-spacer>
          <v-flex xs2 class="text-sm-right">
            <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark color="grey" v-bind="attrs" v-on="on"> mdi-dots-horizontal </v-icon>
            </template>
              <v-list v-if="menuItems.length">
                <v-list-item v-for="(item, index) in menuItems" :key="index">
                  <v-list-item-title @click="item.click(modelObj)">{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-icon dark color="grey"> mdi-dots-horizontal </v-icon> -->
          </v-flex>
        </v-layout>
      </v-responsive>
      <v-card-text>
        <div class="layout ma-0 align-center" :class="cardLayout">
          <v-avatar :size="avatarSize">
            <v-icon v-if="icon" :size="avatarSize - 15" color="white">{{icon}}</v-icon>
            <img v-bind:src="avatar" v-bind:alt="name" v-else-if="showAvatar">
            <span v-else class="white--text display-1 text-capitalize">{{ name.charAt(0) }}</span>
          </v-avatar>
          <div class="flex mt-2" :class="textAlign">
            <div class="subtitle-1 font-weight-black">{{ name }}</div>
            <span class="caption">{{ caption || ''}}</span>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-bottom-navigation :value="true" color="transparent" :height="64" v-if="showBottomNav">
      <v-btn flat color="teal" value="recent">
        <span>Recent</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn flat color="teal" value="favorites">
        <span>Favorites</span>
        <v-icon>mdi-favorite</v-icon>
      </v-btn>
      <v-btn flat color="teal" value="nearby">
        <span>Nearby</span>
        <v-icon>mdi-place</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: null
    },
    caption: {
      type: String,
      default: ''
    },
    cardBgImage: {
      type: String,
    },
    color: {
      type: String,
      default: ''
    },
    dark: {
      type: Boolean,
      default: false
    },
    bottomNav: {
      type: Boolean,
      default: false
    },
    topNav: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: false
    },
    menuItems: {
      type: Array,
      default: () => []
    },
    modelObj: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      colors: ['#ba234b','#e57b09','teal', '#a51288']
    }
  },
  computed: {
    cardLayout () {
      return this.mini ? 'row' : 'column'
    },
    textAlign () {
      return this.mini ? 'text-sm-right' : 'text-sm-center'
    },
    avatarSize () {
      return this.mini ? '54' : '96'
    },
    showAvatar () {
      return this.avatar
    },
    showBottomNav () {
      return !this.mini && this.bottomNav
    },
    showTopNav () {
      return !this.mini && this.topNav
    }
  },
  methods: {
    getRandomColor () {
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    }
  }
}
</script>
